<template>
  <router-view/>
</template>

<style>
  body{
    margin: 0;
    padding: 0;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(40px, auto);
  }

  .title {
    font-family: "Abril Fatface", cursive;
    font-size: 2rem;
    font-weight: 600;
    grid-column: 1 / 1;
    grid-row: 1;
    text-decoration: none;
  }

  .title:hover {
    color: #1E55B3;
  }

  .title:active {
    color: #1E55B3;
  }

  .title:visited {
    color: #1E55B3;
  }

  nav {
    grid-row: 1;
    grid-column: 2 / -1;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: end;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    padding: 0 10px;
    align-self: end;
  }

  nav a.router-link-exact-active {
    color: #1E55B3;
  }


</style>
