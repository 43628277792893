import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDkU3-21--CIaTuj4Ahbfwu6IBamQFTwzE",
  authDomain: "kirsch-becker.firebaseapp.com",
  projectId: "kirsch-becker",
  storageBucket: "kirsch-becker.appspot.com",
  messagingSenderId: "533271204219",
  appId: "1:533271204219:web:56d15e8887727a9964adde"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;