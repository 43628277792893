import { createRouter, createWebHashHistory } from 'vue-router'
import SplashView from '../views/Splash.vue'
import { app } from "../auth/auth.service";
import { getAuth } from "firebase/auth";

const routes = [
  {path: '/',name: 'splash',component: SplashView},
  // {path: '/old',name: 'home',component: HomeView},
  {path: '/preview',name: 'preview', component: () => import(/* webpackChunkName: "about" */ '../views/PreviewView.vue')},
  {path: '/login',name: 'login',component: () => import('../views/LoginView.vue')},
  {path: '/home', name: 'new-home', component: () => import('../views/NewHome.vue')},
  // {path: '/projects', name: 'projects', component: () => import('../views/Projects.vue')},
  // {path: '/about', name: 'about', component: () => import('../views/AboutView.vue')},
  // {path: '/splash', name: 'splash-static', component: () => import('../views/Splash.vue')},
  {path: '/profile', name: 'profile', component: () => import('../views/ProfileView.vue')},

  {path: '/projects/pricetransparancy', name: 'price-transparancy',component: () => import( '../views/PriceTransparancy/PriceTransparancyHome.vue')},
  {path: '/projects/pricetransparancy/build', name: 'price-transparancy-build',component: () => import( '../views/PriceTransparancy/BuildNewDeliverable.vue')},
]

const unprotectedRoutes = ['splash', 'preview', 'login', 'new-home'];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    const auth = getAuth(app);
    auth.authStateReady().then(() => {
    if (auth.currentUser) {
        console.log("current user");
        next();
    }
    else {
        console.log("no current user");
        if (!unprotectedRoutes.includes(to.name)) {
          next({ name: 'new-home' });
        }
        else {
          next();
        }
    }
  
})
});

export default router
