<template>
    <div class="video-container">
        <video autoplay muted loop playsinline id="myVideo" class="full-height">
        <source src="../assets/v1.mp4" type="video/mp4">
        </video>
    </div>

    <!-- Optional: some overlay text to describe the video -->
    <div class="content" id="cbox">
        Marmion<br />
        Partners
        <div class="enter">
            <span @click="enterSite()">Enter</span>
        </div>
    </div>
</template>
  
  <style>
    .video-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: black;
        
    }

    .full-height {
        height: 100%;
        width: 100%;
        min-height: 720px;
        object-fit: cover;
    }

    .content {
        grid-column: 1/-1;
        grid-row: 4 / 7;
        background: rgba(19, 19, 19, 0.8);
        color: rgb(236, 236, 236);
        width: 100%;
        z-index: 2;

        font-family: 'Montserrat', sans-serif;
        font-size: clamp(3rem, 8vw, 100rem);
        text-align: left;
        font-weight: 100;

        padding-bottom: 1%;
    }

    .enter {
        text-align: center;
        grid-column: 3;
        padding: 10px 20px;
        text-align: right;
    }

    .enter span{
        font-size: clamp(1.5rem, 2vw, 25rem);
        font-weight: 100;
        border: 1px solid white;
        padding: clamp(5px, 2vw, 50px);
        margin-right: 1%;
        margin-bottom: 1%;
    }

    .enter span:hover{
        cursor: pointer;
        font-size: clamp(1.5rem, 2vw, 25rem);
        font-weight: 100;
        border: 1px solid white;
        background-color: rgb(128, 128, 128);
        color: 19, 19, 19;
        padding: clamp(5px, 2vw, 50px);
        margin-right: 1%;
        margin-bottom: 1%;
    }
  </style>
  
  
  <script>

  
  export default {
    name: 'SplashView',
    components: {

    },
    methods: {
        enterSite: function(){
            //blur background
            document.getElementById('myVideo').style.transition = "all 1s";
            document.getElementById('myVideo').style.filter = "brightness(0)";
            document.getElementById('myVideo').style.filter = "blur(10px)";

            //pause video
            document.getElementById('myVideo').pause();

            
            //sleep for 4 seconds
            setTimeout(this.redirect, 1000);
            //this.$router.push('/home');
        },
        redirect: function(){
            this.$router.push('/home');
        }
    },
  }
  </script>
  